.small-search {
    width: 4.67rem;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #skip  {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #fff;
    z-index: 999999999999999999;
    text-decoration: none;
    font-weight: bold;
    &:focus-within {
      padding: 1em 0;
      height: auto;
      width: 100%;
      background: rgba(0,0,0,0.8);
      border: 1px dotted #ccc;
      border-top: none;
      border-radius: 0 0 6px 6px;
    } 
    &:hover {
      background: black;
    };
    a:first-of-type {
      margin-left: .25em;
    };
  }