@import "./imports";

html {
  height: 100%;
  --mobile-nav-height: 4rem;
  --osu-scarlet: #bb0000;
  --osu-scarlet-20: #960000;
  --osu-gray: #666666;
  --osu-gray-20: #e0e0e0;
  --osu-gray-70: #1e1e1e;
  --root-text-color: black;
  --root-background-color: white;
  --img-border: 0.2px solid rgba(128, 128, 128, 0.3);
  --palette-foreground: black;
  --palette-midground: black;
  --palette-background: black;
  --palette-primary-light: #0f4fc9;
  --palette-primary-dark: #0f4fc9;
  --palette-primary-main: black;
  --palette-primary-contrastTest: black;
  --palette-secondary-light: #0f4fc9;
  --palette-secondary-dark: #0f4fc9;
  --palette-secondary-main: black;
  --palette-secondary-contrastTest: black;
  --palette-error-light: #0f4fc9;
  --palette-error-dark: #0f4fc9;
  --palette-error-main: black;
  --palette-error-contrastTest: black;
  color: var(--root-text-color);
  background-color: var(--root-background-color);
}
select :-moz-any(option) {
  color: var(--root-text-color);
  background-color: var(--options-background-color);
}
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
footer {
  flex-shrink: 0;
}
a {
  color: black
}
.main-wrapper {
  flex: 1 0 auto;
}
.programCardsFragment{
  padding-left: 0px;
}
.h-accessible-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.d-flex {
  display: flex !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.align-items-center {
  align-items: center !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.spaceBetween {
  justify-content: space-between !important;
}
.justify-items-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.m-0-auto {
  margin: 0 auto !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.pointer {
  cursor: pointer !important;
}
.ellipsis {
  text-overflow: ellipsis;
}
footer {
  background-color: #313131;
  color: #fff;
  padding: 45px 0;
  overflow: auto;
  font-size: 13px;
  line-height: 1;
  position: relative;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.keyWords {
  padding-left: 35px;
  padding-right: 36px;
}
footer ul li {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.3;
}

.programchips {
  display: flex;
  text-align: center;
  background-color: #fff;
  color: #000000;
}

.carouselItemImg {
  height: "700px";
}

.clearSelect {
  padding-right:"14px";
}

.filterKeywordsText {
  float: left;
  color: #333;
  padding: 15px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 500;

}

.clearAllLink {
  float: right;
  padding: 30px;
}

.exploreProgramsBox .block-title {
  position: absolute;
  left: 40px;
  top: -30px;
  letter-spacing: 4px;
  font-weight: bold;
  font-size: 20px;
  background: #333;
  color: #FFF;
  text-transform: uppercase;
  padding: 15px 26px;
}

.exploreProgramsBox {
  background-color: #fff;
  padding: 55px 0;
  font-size: 13px;
  line-height: 1;
  position: relative;
  width: 1100px;
  text-align: left;
  border: 1px solid #ccc;
  padding-top:40px;
}

.studentSpotlightBox {
  color: #fff;
  padding: 45px 0;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  left: 50px;
  bottom: 50px;
}

.exploreOSUBox {
  background-color: #fff;
  color: #000000;
  padding: 20px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  left: 50px;
  border: 1px solid #ccc;
}

.questionsBox {
  background-color: #666666;
  color: #fff;
  padding: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  left: 50px;
  bottom: 50px;
}


ul {
  list-style-type: none;
}

@media screen and (max-width: 767px) {
  footer ul li {
    text-align: center;
    font-size: 17px;
    color: #ccc;
    margin-bottom: 8px;
  }
}

footer h5 {
  font-size: 15px;
}

footer .contact {
  width: 30%;
  float: left;
  padding-right: 20px;
}

footer .contact:nth-child(3) {
  width: 15%;
  padding: 0;
}

footer .contact:nth-child(3) .social-list {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  footer .contact:nth-child(3) .social-list {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  footer .contact:nth-child(3) {
    width: 100%;
  }
}

footer .contact:nth-child(2) {
  width: 55%;
}

@media screen and (max-width: 767px) {
  footer .contact:nth-child(2) {
    width: 100%;
  }
}

footer .contact h5 {
  text-transform: uppercase;
  color: #fff;
  line-height: 1.2;
}

footer .contact-links a:first-of-type {
  padding-right: 5px;
}

footer .contact-links a:last-of-type {
  padding-left: 5px;
}

footer .contact .general-address a {
  line-height: 1.3;
}

@media screen and (max-width: 1200px) {
  footer .contact {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  footer .contact {
    float: none;
    width: 100%;
    margin-bottom: 35px;
    padding-right: 0;
  }

  footer .contact h5 {
    font-family: "proxima_nova_rgregular", sans-serif;
    text-align: center;
    font-size: 19px;
    padding: 0 58px;
  }
}

footer .footer-details {
  float: right;
  width: calc(70% - 60px);
}

footer .footer-details .social a {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

footer .footer-details .social a:last-of-type {
  margin-right: 0;
}

footer .footer-details .social a.facebook {
  background-image: url("../Common/assets/facebook.svg");
}

footer .footer-details .social a.twitter {
  background-image: url("../Common/assets/twitter.svg");
}

footer .footer-details .social a.instagram {
  background-image: url("../Common/assets/instagram.svg");
}

footer .footer-details a {
  color: #fff;
  transition: 0.4s;
}

footer .footer-details a.underline-link {
  border-bottom: 1px solid #fff;
}

footer .footer-details a.underline-link:hover {
  border-bottom: 1px solid #dfea52;
}

footer .footer-details a:hover {
  color: #dfea52;
  transition: 0.4s;
}

@media screen and (max-width: 767px) {
  footer .footer-details a {
    color: #ccc;
  }
}

@media screen and (max-width: 992px) {
  footer .footer-details {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 767px) {
  footer .footer-details {
    width: 100%;
  }
}

footer .osu-logo {
  float: left;
  width: 30%;
}

footer .osu-logo img {
  margin-bottom: 20px;
  width: 100%;
}

@media screen and (max-width: 992px) {
  footer .osu-logo {
    float: left;
    clear: both;
    width: 50%;
    text-align: left;
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  footer .osu-logo {
    float: none;
    text-align: center;
    width: 100%;
  }

  footer .osu-logo img {
    display: block;
    margin: 15px auto;
    max-width: 300px;
    width: 82%;
  }
}

footer .legal {
  margin-top: 30px;
  float: left;
  width: 100%;
}

@media screen and (max-width: 767px) {
  footer .legal {
    text-align: center;
  }
}

footer .legal p {
  display: inline;
  padding-right: 10px;
}

footer .legal a {
  color: #fff;
  padding: 0 10px;
}

footer .legal a:last-of-type {
  padding: 0 0 0 10px;
}

footer .legal a:hover {
  color: #dfea52;
  transition: color 0.4s;
}

// .selectStyle {
//   margin-bottom: 30px;
//   padding-bottom: 15px;
// }