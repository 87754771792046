.heroImage {
    position: relative;
    width:100%;
    height: 466px;
    background-size: cover;
    background-position: left top;
    background-image: url("../../Common/assets/images/background.jpg");
    background-repeat: no-repeat;
}

.slanted-1 {
    background: transparent;
    background-repeat: repeat;
    padding: 10px 0 0;
    margin-top: 0px;
    z-index: 0;
    position: relative;
    background: #FFF;
  }
  
  .slanted-1:before {
    top: 0;
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    padding-bottom: 600px;
    background-color: #fff;
    background: inherit;
    content: "";
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }
  