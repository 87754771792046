$increment: .5;
@for $i from 0 through 5 {
  .m-#{$i} {
    margin: #{$i * $increment}rem !important;
  }
  .mt-#{$i},
  .my-#{$i} {
    margin-top: #{$i * $increment}rem !important;
  }
  /*.mb-#{$i},
  .my-#{$i} {
    margin-bottom: #{$i * $increment}rem !important;
  }*/
  .mx-#{$i},
  .ml-#{$i} {
    margin-left: #{$i * $increment}rem !important;
  }
  .mx-#{$i},
  .mr-#{$i} {
    margin-right: #{$i * $increment}rem !important;
  }
  .p-#{$i} {
    padding: #{$i * $increment}rem !important;
  }
  .pt-#{$i},
  .py-#{$i} {
    padding-top: #{$i * $increment}rem !important;
  }
  .pb-#{$i},
  .py-#{$i} {
    padding-bottom: #{$i * $increment}rem !important;
  }
  .px-#{$i},
  .pl-#{$i} {
    padding-left: #{$i * $increment}rem !important;
  }
  .px-#{$i},
  .pr-#{$i} {
    padding-right: #{$i * $increment}rem !important;
  }
}